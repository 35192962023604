import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { TarifasData } from 'src/app/footer/_models/tarifas-data';

@Component({
  selector: 'app-tarifas-factoring',
  templateUrl: './tarifas-factoring.component.html',
  styleUrls: ['./tarifas-factoring.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})

export class TarifasFactoringComponent
{
  activeTab: number = 0;

  constructor(
    public dialogRef: MatDialogRef<TarifasFactoringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TarifasData
  ) { }

  onTabChange(event: any): void
  {
    this.activeTab = event;
  }
}
