<div id="dialog-tarifas-factoring" class="dialog-container dialog-scroll container-fluid">
  <div class="header-dialog">
    <div class="text-end mb-2">
      <span class="material-symbols-outlined" [mat-dialog-close]="true" role="button">close</span>
    </div>
    <h3 class="me-4">Conoce las tarifas<sup>*</sup> de SEGO Factoring</h3>
  </div>

  <div class="linea-separadora-1 border-primary-100 my-3"></div>

  <mat-tab-group mat-stretch-tabs (selectedIndexChange)="onTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <p class="mb-0 {{activeTab === 0 ? 'tabs' : 'tabs2'}}">Invertir</p>
      </ng-template>
      <div class="my-4">
        <p class="p1-regular mb-3">Como inversor de SEGO Factoring, <span class="p1-semibold">solo se te cobrará
          una
        tarifa sobre los beneficios obtenidos</span>.
      </p>

      <div class="tarifas-tabla border-secondary-factoring-200 text-center mb-3 p-4">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <p class="p1-cifras primary-900 mb-0">16,53%</p>
            <p class="p3-semibold primary-800">IVA no incluido</p>
            <p class="p1-medium neutral-900">sobre tus beneficios</p>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block my-4 text-center">
        @if (data.plataformaId === 'FACTORING') {
          <a class="btn btn-primary mx-auto"
            title="Empieza a invertir" routerLink="/buscas-financiacion" [mat-dialog-close]="true">Empieza a
          invertir</a>
        }
        @if (data.plataformaId !== 'FACTORING') {
          <a class="btn btn-primary mx-auto"
            title="Empieza a invertir" href="{{data.domainUrl}}/buscas-financiacion"
          [mat-dialog-close]="true">Empieza a invertir</a>
        }
      </div>

      <p class="mt-4 mt-md-0  mb-mobile mb-lg-2 neutral-800 p3-regular">(*) Todas nuestras tarifas son sin
        comisiones añadidas, tanto para inversores como para empresas que buscan financiación para adelantar
        sus facturas.
      </p>

      <div class="d-block d-md-none fixed-bottom bg-white">

        <div class="linea-separadora-1 border-primary-100 my-4"></div>

        <div class="row">
          <div class="col-10 mx-auto text-center mb-4">
            @if (data.plataformaId === 'FACTORING') {
              <a class="btn btn-primary w-100"
                title="Empieza a invertir" routerLink="/buscas-financiacion"
              [mat-dialog-close]="true">Empieza a invertir</a>
            }
            @if (data.plataformaId !== 'FACTORING') {
              <a class="btn btn-primary w-100"
                title="Empieza a invertir" href="{{data.domainUrl}}/buscas-financiacion"
                [mat-dialog-close]="true">Empieza
              a invertir</a>
            }
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <p class="mb-0 {{activeTab === 1 ? 'tabs' : 'tabs2'}}">Adelantar facturas</p>
    </ng-template>
    <div class="my-4">
      <p class="p1-regular mb-3">Como empresa cedente, <span class="p1-semibold">te descontaremos una comisión
        fija
        en el
        momento de liberar los fondos. El tipo de interés se cobra al recibir el pago de cada
      factura</span> por parte del deudor.</p>

      <div class="tarifas-tabla bg-secondary-factoring-200 text-center mb-3 p-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-5">
            <p class="tabs primary-500">Fijo</p>
            <p class="p1-cifras primary-900 mb-0">0,5% - 2%</p>
            <p class="p3-semibold primary-800">IVA no incluido</p>
            <p class="p1-medium neutral-900">en cada factura<br> descontada</p>
          </div>
          <div class="col-md-2">
            <p class="p1-cifras primary-900">+</p>
          </div>
          <div class="col-md-5">
            <p class="tabs primary-500">Variable</p>
            <p class="p1-cifras primary-900 mb-0">4,5% - 10%</p>
            <p class="p3-semibold primary-800">IVA no incluido</p>
            <p class="p1-medium neutral-900">tipo de interés<br> anual</p>
          </div>
        </div>
      </div>

      <p class="p1-semibold mb-2">El cálculo correspondiente a la tarifa variable se hace en función de: </p>

      <ul class="list-unstyled p2-regular ms-2">
        <li class="neutral-800">
          <span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span> Análisis de tu
          deudor
        </li>
        <li class="neutral-800">
          <span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span> Histórico
        </li>
        <li class="neutral-800">
          <span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span> Plazo
        </li>
      </ul>

      <div class="d-none d-md-block my-4 text-center">
        @if (data.plataformaId === 'FACTORING') {
          <a class="btn btn-primary mx-auto"
            title="Adelanta tus facturas" routerLink="/buscas-financiacion"
          [mat-dialog-close]="true">Adelanta tus facturas</a>
        }
        @if (data.plataformaId !== 'FACTORING') {
          <a class="btn btn-primary mx-auto"
            title="Adelanta tus facturas" href="{{data.domainUrl}}/buscas-financiacion"
          [mat-dialog-close]="true">Adelanta tus facturas</a>
        }
      </div>

      <p class="mt-4 mt-md-0  mb-mobile mb-lg-2 neutral-800 p3-regular">(*) Todas nuestras tarifas son sin
        comisiones añadidas, tanto para inversores como para empresas que buscan financiación para adelantar
        sus facturas.
      </p>

      <div class="d-block d-md-none fixed-bottom bg-white">

        <div class="linea-separadora-1 border-primary-100 my-4"></div>

        <div class="row">
          <div class="col-10 mx-auto text-center mb-4">
            @if (data.plataformaId === 'FACTORING') {
              <a class="btn btn-primary w-100"
                title="Adelanta tus facturas" routerLink="/buscas-financiacion"
              [mat-dialog-close]="true">Adelanta tus facturas</a>
            }
            @if (data.plataformaId !== 'FACTORING') {
              <a class="btn btn-primary w-100"
                title="Adelanta tus facturas" href="{{data.domainUrl}}/buscas-financiacion"
                [mat-dialog-close]="true">Adelanta
              tus facturas</a>
            }
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
</div>